/*
 * MasterPage Messages
 *
 * This contains all the text for the TestSelectPage container.
 */

import { defineMessages } from 'react-intl';
import { config } from '../../config';

export const scope = 'app.containers.MasterPage';

export default defineMessages({
  mainNavigation: {
    id: `${scope}.mainNavigation`,
    defaultMessage: 'Main Navigation',
  },
  metaDescription: {
    id: `${scope}.metaDescription`,
    defaultMessage: config.REACT_APP_META_HOME_PAGE_DESCRIPTION,
  },
  closeButton: {
    id: `${scope}.closeButton`,
    defaultMessage: 'Close',
  },
  logoHome: {
    id: `${scope}.logoHome`,
    defaultMessage: 'LawHub Home',
  },
  breadcrumbNav: {
    id: `${scope}.breadcrumbNav`,
    defaultMessage: 'Breadcrumb',
  },
  showAnswerLabel: {
    id: `${scope}.showAnswerLabel`,
    defaultMessage: 'the correct answer option is {correctAnswerOption}',
  },
  showAnswerPopUpMessage: {
    id: `${scope}.showAnswerPopUpMessage`,
    defaultMessage:
      'Once the correct answer is displayed, functions such as changing your answer and highlighting will be disabled.  Do you wish to proceed?',
  },
  showAnswerCancelButton: {
    id: `${scope}.showAnswerCancelButton`,
    defaultMessage: 'Cancel',
  },
  showAnswerContinueButton: {
    id: `${scope}.showAnswerContinueButton`,
    defaultMessage: 'Continue',
  },
  neverShowAnswerPopUp: {
    id: `${scope}.neverShowAnswerPopUp`,
    defaultMessage: 'Don’t show me this message again',
  },
  pauseSectionPopup: {
    id: `${scope}.pauseSectionPopup`,
    defaultMessage:
      'Note that pause functionality will not be available during the real exam.',
  },
  pauseSectionPopupNavigate: {
    id: `${scope}.pauseSectionPopupNavigate`,
    defaultMessage:
      'Navigating to this page will pause the test. Note that pause functionality will not be available during the real exam.',
  },
  pauseSectionPopupOk: {
    id: `${scope}.pauseSectionPopupOk`,
    defaultMessage: 'Ok',
  },
  notice: {
    id: `${scope}.notice`,
    defaultMessage: 'Notice',
  },
  neverShowPausePopUp: {
    id: `${scope}.neverShowPausePopUp`,
    defaultMessage: 'Don’t show me this message again',
  },
  completeSectionButton: {
    id: `${scope}.completeSectionButton`,
    defaultMessage: 'Complete Section',
  },
  submitButton: {
    id: `${scope}.submitButton`,
    defaultMessage: 'Submit',
  },
  backButton: {
    id: `${scope}.backButton`,
    defaultMessage: 'Back',
  },
  nextSectionButton: {
    id: `${scope}.nextSectionButton`,
    defaultMessage: 'Next Section',
  },
  technicalIssueLinkText: {
    id: `${scope}.technicalIssueLinkText`,
    defaultMessage:
      'In the event of a technical issue requiring proctor intervention click here',
  },
  helpLinkText: {
    id: `${scope}.helpLinkText`,
    defaultMessage: 'Help',
  },
  prepLinkText: {
    id: `${scope}.prepLinkText`,
    defaultMessage: 'LSAT Prep',
  },
  lsatPrepTestLinkText: {
    id: `${scope}.lsatPrepTestLinkText`,
    defaultMessage: 'LSAT Test Prep',
  },
  prepPlusLinkText: {
    id: `${scope}.prepPlusLinkText`,
    defaultMessage: 'LSAT Prep Plus',
  },
  dashboardLinkText: {
    id: `${scope}.dashboardLinkText`,
    defaultMessage: 'Home',
  },
  exploreLinkText: {
    id: `${scope}.exploreLinkText`,
    defaultMessage: 'Explore',
  },
  learningLibraryLinkText: {
    id: `${scope}.learningLibraryLinkText`,
    defaultMessage: 'Learning Library',
  },
  marketplaceLinkText: {
    id: `${scope}.marketplaceLinkText`,
    defaultMessage: 'Marketplace',
  },
  recommendedReadingLinkText: {
    id: `${scope}.recommendedReadingLinkText`,
    defaultMessage: 'Recommended Reading',
  },
  tutorialsLinkText: {
    id: `${scope}.tutorialsLinkText`,
    defaultMessage: 'Tutorials',
  },
  myAccountLinkText: {
    id: `${scope}.myAccountLinkText`,
    defaultMessage: 'My Account',
  },
  upgradeLinkText: {
    id: `${scope}.upgradeLinkText`,
    defaultMessage: 'Upgrade to LawHub&nbsp;Advantage',
  },
  lsatLinkText: {
    id: `${scope}.lsatLinkText`,
    defaultMessage: `${config.REACT_APP_LSAT_NAME}`,
  },
  signOutButtonText: {
    id: `${scope}.signOutButtonText`,
    defaultMessage: 'Sign-Out',
  },
  lstLinkText: {
    id: `${scope}.lstLinkText`,
    defaultMessage: 'Law School Transparency',
  },
});
