/**
 *
 * AppInitPage
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Container, Row } from 'reactstrap';
import AriadneLoading from 'components/ariadne/AriadneLoading/AriadneLoading';
import history from 'utils/history';
import {
  initSagaLibrarySelector,
  userDetailsSelector,
  errorSelector,
  configDetailsSelector,
  configErrorSelector,
} from 'domains/session/selectors';
import {
  getUserDetails,
  putIsAppReadyAction,
  getConfigDetailsAction,
  postLoginCountAction,
} from 'domains/session/actions';
import { useSessionReduxAndSaga } from 'domains/session/hooks';
import { getUser } from '../../authProvider';
import { getAndPersistSearchParamForSession } from '../../helpers/historyHelper';
import { initializePersistence } from '../../helpers/persistence/persistenceManagementHelper';
import { config } from '../../config';
import {
  saveDataFromLocalStorage,
  setupTimedSave,
} from '../../helpers/telemetryHelper';

// we have a pretty complex flow, so let's track current state in a state machine
const pageStates = {
  STATE_WAITING_FOR_SAGA: 0,
  STATE_READY_TO_REQUEST_USER_DETAILS: 1,
  STATE_READY_TO_REQUEST_CONFIG_DETAILS: 12,
  STATE_WAITING_FOR_USER_DETAILS: 2,
  STATE_RETRIEVED_USER_DETAILS: 3,
  STATE_USER_DETAILS_ERROR: 10,
  STATE_WAITING_FOR_CONFIG_DETAILS: 4,
  STATE_RETRIEVED_CONFIG_DETAILS: 5,
  STATE_CONFIG_ERROR: 11,
  STATE_INITIALIZING_PERSISTENCE: 6,
  STATE_APP_READY: 7,
};

let configurationChangesIntervalId = null;
let configurationChangesInterval = parseInt(
  config.REACT_APP_CONFIG_POLLING_INTERVAL,
  10,
);
// Referral code key in search params
const REFERRAL_CODE_KEY = 'refcode';

export function AppInitPage({
  initSagaLibrary,
  userDetails,
  onLoadUserDetails,
  onPutIsAppReady,
  onPostLoginCount,
  onGetConfigDetails,
  configDetails,
  configError,
  userDetailsError,
}) {
  useSessionReduxAndSaga();
  const [state, setState] = useState(pageStates.STATE_WAITING_FOR_SAGA);

  useEffect(() => {
    switch (state) {
      case pageStates.STATE_WAITING_FOR_SAGA:
        // Persist referral code search param in sessionStorage
        getAndPersistSearchParamForSession(REFERRAL_CODE_KEY);

        if (initSagaLibrary) {
          setState(pageStates.STATE_READY_TO_REQUEST_CONFIG_DETAILS);
        }
        break;
      case pageStates.STATE_READY_TO_REQUEST_CONFIG_DETAILS:
        setState(pageStates.STATE_WAITING_FOR_CONFIG_DETAILS);
        onGetConfigDetails();
        break;
      case pageStates.STATE_WAITING_FOR_CONFIG_DETAILS:
        if (configDetails) {
          setState(pageStates.STATE_READY_TO_REQUEST_USER_DETAILS);
        } else if (configError) {
          onGetConfigDetails();
        }
        break;
      case pageStates.STATE_READY_TO_REQUEST_USER_DETAILS:
        if (getUser()) {
          setState(pageStates.STATE_WAITING_FOR_USER_DETAILS);
          onLoadUserDetails();
          break;
        }
        setState(pageStates.STATE_RETRIEVED_CONFIG_DETAILS);
        break;
      case pageStates.STATE_WAITING_FOR_USER_DETAILS:
        if (userDetails) {
          onPostLoginCount();
          setState(pageStates.STATE_RETRIEVED_USER_DETAILS);
        }
        if (userDetailsError) {
          onLoadUserDetails();
        }
        break;
      case pageStates.STATE_RETRIEVED_CONFIG_DETAILS:
      case pageStates.STATE_RETRIEVED_USER_DETAILS:
        setState(pageStates.STATE_INITIALIZING_PERSISTENCE);
        if (getUser()) {
          initializePersistence().then(() => {
            setupTimedSave();
            setState(pageStates.STATE_APP_READY);
            onPutIsAppReady();

            // Save Outstanding Telemetry data from local storage when a user logs in
            saveDataFromLocalStorage();
          });
        } else {
          setState(pageStates.STATE_APP_READY);
          onPutIsAppReady();
        }
        break;
      case pageStates.STATE_APP_READY:
        {
          startConfigurationChangesInterval();
          startConfigurationUpdateHelperInterval();

          const query = new URLSearchParams(window.location.search);
          const orig = query.get('url');
          history.replace(orig);
        }
        break;
      default:
        break;
    }
  }, [
    state,
    initSagaLibrary,
    userDetails,
    configDetails,
    userDetailsError,
    configError,
  ]);

  function startConfigurationChangesInterval() {
    configurationChangesIntervalId = window.setInterval(() => {
      onGetConfigDetails();
      if (getUser()) {
        onLoadUserDetails();
      }
    }, parseInt(config.REACT_APP_CONFIG_POLLING_INTERVAL, 10) * 1000);
  }

  function startConfigurationUpdateHelperInterval() {
    window.setInterval(() => {
      if (
        configurationChangesInterval !==
        parseInt(config.REACT_APP_CONFIG_POLLING_INTERVAL, 10)
      ) {
        configurationChangesInterval = parseInt(
          config.REACT_APP_CONFIG_POLLING_INTERVAL,
          10,
        );

        stopConfigurationChangesInterval();
        startConfigurationChangesInterval();
      }
    }, 1000);
  }

  function stopConfigurationChangesInterval() {
    clearInterval(configurationChangesIntervalId);
  }

  return (
    <Container fluid style={{ width: '100vw' }}>
      <Row>
        <AriadneLoading text="session data" />
      </Row>
    </Container>
  );
}

AppInitPage.propTypes = {
  initSagaLibrary: PropTypes.bool.isRequired,
  userDetails: PropTypes.object,
  onLoadUserDetails: PropTypes.func,
  onPutIsAppReady: PropTypes.func,
  onPostLoginCount: PropTypes.func,
  onGetConfigDetails: PropTypes.func,
  configDetails: PropTypes.object,
  configError: PropTypes.object,
  userDetailsError: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  initSagaLibrary: initSagaLibrarySelector(),
  userDetails: userDetailsSelector(),
  configDetails: configDetailsSelector(),
  configError: configErrorSelector(),
  userDetailsError: errorSelector(),
});

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    onLoadUserDetails: () => dispatch(getUserDetails()),
    onPostLoginCount: () => dispatch(postLoginCountAction()),
    onPutIsAppReady: () => dispatch(putIsAppReadyAction()),
    onGetConfigDetails: () => dispatch(getConfigDetailsAction()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(AppInitPage);
